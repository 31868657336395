import React, { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import logo from "../img/logo-black.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import EnquiryForm from "../components/enquiry";
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import ContactUsBanner from "../img/contact-us.jpg";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function ContactUs() {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: '#my-hubspot-form-new'
});

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });

  return (
    <div>
      <Helmet>
        <title>OIC - Contact Us</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url(` + ContactUsBanner + `)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  BUILD YOUR SUCCESSFUL <br></br>
                  <span> BUSINESS WITH US</span>
                </h1>
                <a
                  onClick={() => {
                    scrollTo("#ContactForm");
                  }}
                  className="buttonStyleWhite hvr-forward"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" id="ContactForm">
          <div className="container">
            <div className="row " data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-6">
                <h2 className="realistic-marker-highlight">Contact Us</h2>
                {" "}
                <img src={logo} width="180" alt="OiC App" />
                <br></br>
                <br></br>
                <p>
                
                  Polaris House,<br></br> No. 244, Anna Salai,<br></br> Chennai, Tamil Nadu 600006 <br></br>
                </p>
                <p><b>Phone :</b> +91 97918 36596
                <p><b>Email :</b>  Marketing@oicapps.com</p>
                </p>

                <p><br></br><br></br>
                <b> Registered Address : </b><br></br>

ADHYAS SOFTWARE PRIVATE LIMITED<br></br>
128, DEFENCE COLONY, <br></br>
EKKATUTHANGAL, Chennai,<br></br>
Tamil Nadu, 600032
                </p>
              </div>
              <div className="col-md-6">
               
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
